'use strict';

// Hamburger

var fullScreenInfoRow = document.querySelector('.main-content');
var fullScreenInfo = document.querySelector('.menu-nav');
var burgerMenu = document.querySelector('.burger-btn');
var burgerMenuClose = document.querySelector('.burger-close');
if (burgerMenu) {
   burgerMenu.addEventListener("click", function (e) {
      document.body.classList.add('js-lock');
      fullScreenInfoRow.classList.add('js-is-active');
      fullScreenInfo.classList.add('js-is-active');
   });
}
if (burgerMenuClose) {
   burgerMenuClose.addEventListener("click", function (e) {
      document.body.classList.remove('js-lock');
      fullScreenInfoRow.classList.remove('js-is-active');
      fullScreenInfo.classList.remove('js-is-active');
   });
}